/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: a26b0b3e (Wed Aug 21 18:31:49 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
import {
  Address,
  AddressFromJSON,
  AddressToJSON,
  DetailMeta,
  DetailMetaFromJSON,
  DetailMetaToJSON,
} from './'

/**
 *
 * @export
 * @interface CreateCommunityResponse
 */
export interface CreateCommunityResponse {
  /**
   * Community unique identifier
   * @type {number}
   * @memberof CreateCommunityResponse
   */
  communityKey?: number
  /**
   * Name of the community.
   * @type {string}
   * @memberof CreateCommunityResponse
   */
  name: string
  /**
   * Unique region identifier for a foreign key relationship from community to region.
   * @type {number}
   * @memberof CreateCommunityResponse
   */
  regionsKey: number
  /**
   *
   * @type {Address}
   * @memberof CreateCommunityResponse
   */
  address: Address
  /**
   * The unique identifier of the actor of 'Support Representative' for this particular community
   * @type {number}
   * @memberof CreateCommunityResponse
   */
  actorKey: number
  /**
   * The unique identifier of the contractual model for a tutor. This model is configured at a community level and specifies how all tutors for a community's programs are hired.
   * @type {string}
   * @memberof CreateCommunityResponse
   */
  tutorModel: string
  /**
   * The percentage of fees given to the tutor, stored as a fraction i.e. 12.34% stored as 0.1234.
   * @type {number}
   * @memberof CreateCommunityResponse
   */
  feePercentage?: number
  /**
   * The hideFromPublicSearch prevents a community from being displayed on the marketing site.
   * @type {boolean}
   * @memberof CreateCommunityResponse
   */
  hideFromPublicSearch: boolean
  /**
   *
   * @type {DetailMeta}
   * @memberof CreateCommunityResponse
   */
  meta?: DetailMeta
}

export function CreateCommunityResponseFromJSON(
  json: any
): CreateCommunityResponse {
  return CreateCommunityResponseFromJSONTyped(json, false)
}

export function CreateCommunityResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CreateCommunityResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    communityKey: !exists(json, 'communityKey')
      ? undefined
      : json['communityKey'],
    name: json['name'],
    regionsKey: json['regionsKey'],
    address: AddressFromJSON(json['address']),
    actorKey: json['actorKey'],
    tutorModel: json['tutorModel'],
    feePercentage: !exists(json, 'feePercentage')
      ? undefined
      : json['feePercentage'],
    hideFromPublicSearch: json['hideFromPublicSearch'],
    meta: !exists(json, 'meta') ? undefined : DetailMetaFromJSON(json['meta']),
  }
}

export function CreateCommunityResponseToJSON(
  value?: CreateCommunityResponse | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    communityKey: value.communityKey,
    name: value.name,
    regionsKey: value.regionsKey,
    address: AddressToJSON(value.address),
    actorKey: value.actorKey,
    tutorModel: value.tutorModel,
    feePercentage: value.feePercentage,
    hideFromPublicSearch: value.hideFromPublicSearch,
    meta: DetailMetaToJSON(value.meta),
  }
}
