/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: a26b0b3e (Wed Aug 21 18:31:49 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  CashAmount,
  CashAmountFromJSON,
  CashAmountToJSON,
  FeeType,
  FeeTypeFromJSON,
  FeeTypeToJSON,
} from './'

/**
 *
 * @export
 * @interface Fee
 */
export interface Fee {
  /**
   *
   * @type {FeeType}
   * @memberof Fee
   */
  feeType: FeeType
  /**
   *
   * @type {CashAmount}
   * @memberof Fee
   */
  amount: CashAmount
}

export function FeeFromJSON(json: any): Fee {
  return FeeFromJSONTyped(json, false)
}

export function FeeFromJSONTyped(json: any, ignoreDiscriminator: boolean): Fee {
  if (json === undefined || json === null) {
    return json
  }
  return {
    feeType: FeeTypeFromJSON(json['feeType']),
    amount: CashAmountFromJSON(json['amount']),
  }
}

export function FeeToJSON(value?: Fee | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    feeType: FeeTypeToJSON(value.feeType),
    amount: CashAmountToJSON(value.amount),
  }
}
