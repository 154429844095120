/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: a26b0b3e (Wed Aug 21 18:31:49 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * The status of a students enrollment in a program.
 * @export
 * @enum {string}
 */
export enum EnrollmentStatus {
  Pending = 'PENDING',
  Enrolled = 'ENROLLED',
  MemberHalf = 'MEMBER_HALF',
  MemberFull = 'MEMBER_FULL',
  Drop = 'DROP',
  Complete = 'COMPLETE',
}

export function EnrollmentStatusFromJSON(json: any): EnrollmentStatus {
  return EnrollmentStatusFromJSONTyped(json, false)
}

export function EnrollmentStatusFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): EnrollmentStatus {
  return json as EnrollmentStatus
}

export function EnrollmentStatusToJSON(value?: EnrollmentStatus | null): any {
  return value as any
}
