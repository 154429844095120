/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: a26b0b3e (Wed Aug 21 18:31:49 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface LicensingBillStudents
 */
export interface LicensingBillStudents {
  /**
   *
   * @type {number}
   * @memberof LicensingBillStudents
   */
  studentKey: number
  /**
   *
   * @type {string}
   * @memberof LicensingBillStudents
   */
  studentName: string
}

export function LicensingBillStudentsFromJSON(
  json: any
): LicensingBillStudents {
  return LicensingBillStudentsFromJSONTyped(json, false)
}

export function LicensingBillStudentsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): LicensingBillStudents {
  if (json === undefined || json === null) {
    return json
  }
  return {
    studentKey: json['studentKey'],
    studentName: json['studentName'],
  }
}

export function LicensingBillStudentsToJSON(
  value?: LicensingBillStudents | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    studentKey: value.studentKey,
    studentName: value.studentName,
  }
}
