/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: a26b0b3e (Wed Aug 21 18:31:49 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
/**
 *
 * @export
 * @interface PublicEventLocation
 */
export interface PublicEventLocation {
  /**
   *
   * @type {number}
   * @memberof PublicEventLocation
   */
  distanceMiles?: number
  /**
   *
   * @type {string}
   * @memberof PublicEventLocation
   */
  city?: string
  /**
   *
   * @type {string}
   * @memberof PublicEventLocation
   */
  state?: string
  /**
   *
   * @type {string}
   * @memberof PublicEventLocation
   */
  postalCode?: string
  /**
   *
   * @type {string}
   * @memberof PublicEventLocation
   */
  countryCode?: string
}

export function PublicEventLocationFromJSON(json: any): PublicEventLocation {
  return PublicEventLocationFromJSONTyped(json, false)
}

export function PublicEventLocationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PublicEventLocation {
  if (json === undefined || json === null) {
    return json
  }
  return {
    distanceMiles: !exists(json, 'distanceMiles')
      ? undefined
      : json['distanceMiles'],
    city: !exists(json, 'city') ? undefined : json['city'],
    state: !exists(json, 'state') ? undefined : json['state'],
    postalCode: !exists(json, 'postalCode') ? undefined : json['postalCode'],
    countryCode: !exists(json, 'countryCode') ? undefined : json['countryCode'],
  }
}

export function PublicEventLocationToJSON(
  value?: PublicEventLocation | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    distanceMiles: value.distanceMiles,
    city: value.city,
    state: value.state,
    postalCode: value.postalCode,
    countryCode: value.countryCode,
  }
}
