/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: a26b0b3e (Wed Aug 21 18:31:49 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  EnrollmentInvoiceStubEnrollments,
  EnrollmentInvoiceStubEnrollmentsFromJSON,
  EnrollmentInvoiceStubEnrollmentsToJSON,
} from './'

/**
 * Represents the info needed to materialize the amount due for a set of enrollments.
 * @export
 * @interface EnrollmentInvoiceStub
 */
export interface EnrollmentInvoiceStub {
  /**
   *
   * @type {string}
   * @memberof EnrollmentInvoiceStub
   */
  type: EnrollmentInvoiceStubTypeEnum
  /**
   *
   * @type {Array<EnrollmentInvoiceStubEnrollments>}
   * @memberof EnrollmentInvoiceStub
   */
  enrollments: Array<EnrollmentInvoiceStubEnrollments>
}

/**
 * @export
 * @enum {string}
 */
export enum EnrollmentInvoiceStubTypeEnum {
  Enrollment = 'Enrollment',
}

export function EnrollmentInvoiceStubFromJSON(
  json: any
): EnrollmentInvoiceStub {
  return EnrollmentInvoiceStubFromJSONTyped(json, false)
}

export function EnrollmentInvoiceStubFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): EnrollmentInvoiceStub {
  if (json === undefined || json === null) {
    return json
  }
  return {
    type: json['type'],
    enrollments: (json['enrollments'] as Array<any>).map(
      EnrollmentInvoiceStubEnrollmentsFromJSON
    ),
  }
}

export function EnrollmentInvoiceStubToJSON(
  value?: EnrollmentInvoiceStub | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    type: value.type,
    enrollments: (value.enrollments as Array<any>).map(
      EnrollmentInvoiceStubEnrollmentsToJSON
    ),
  }
}
