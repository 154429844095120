/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: a26b0b3e (Wed Aug 21 18:31:49 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
import { CashAmount, CashAmountFromJSON, CashAmountToJSON } from './'

/**
 * Director licensing due information
 * @export
 * @interface LicensingDue
 */
export interface LicensingDue {
  /**
   * Licensing Due text color when total due is greater than 0
   * @type {string}
   * @memberof LicensingDue
   */
  textColor?: string
  /**
   *
   * @type {CashAmount}
   * @memberof LicensingDue
   */
  total?: CashAmount
}

export function LicensingDueFromJSON(json: any): LicensingDue {
  return LicensingDueFromJSONTyped(json, false)
}

export function LicensingDueFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): LicensingDue {
  if (json === undefined || json === null) {
    return json
  }
  return {
    textColor: !exists(json, 'textColor') ? undefined : json['textColor'],
    total: !exists(json, 'total')
      ? undefined
      : CashAmountFromJSON(json['total']),
  }
}

export function LicensingDueToJSON(value?: LicensingDue | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    textColor: value.textColor,
    total: CashAmountToJSON(value.total),
  }
}
