/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: a26b0b3e (Wed Aug 21 18:31:49 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
/**
 * Basic user information used for creation.
 * @export
 * @interface CreateUserRequestParams
 */
export interface CreateUserRequestParams {
  /**
   * First name of the user.
   * @type {string}
   * @memberof CreateUserRequestParams
   */
  firstName: string
  /**
   * Last name of the user.
   * @type {string}
   * @memberof CreateUserRequestParams
   */
  lastName: string
  /**
   * The personal email the user registered with.
   * @type {string}
   * @memberof CreateUserRequestParams
   */
  username: string
  /**
   * An official, CC-assigned and -managed email address. This cannot be set or changed by most users.
   * @type {string}
   * @memberof CreateUserRequestParams
   */
  email?: string
  /**
   * The phone number associated to the user. It is optional to be set on creation. It can be updated by the user at a late time.
   * @type {string}
   * @memberof CreateUserRequestParams
   */
  phone?: string
  /**
   * The user’s country of residence
   * @type {string}
   * @memberof CreateUserRequestParams
   */
  countryOfResidence?: string
  /**
   * The user’s country of citizenship
   * @type {string}
   * @memberof CreateUserRequestParams
   */
  countryOfCitizenship?: string
}

export function CreateUserRequestParamsFromJSON(
  json: any
): CreateUserRequestParams {
  return CreateUserRequestParamsFromJSONTyped(json, false)
}

export function CreateUserRequestParamsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CreateUserRequestParams {
  if (json === undefined || json === null) {
    return json
  }
  return {
    firstName: json['firstName'],
    lastName: json['lastName'],
    username: json['username'],
    email: !exists(json, 'email') ? undefined : json['email'],
    phone: !exists(json, 'phone') ? undefined : json['phone'],
    countryOfResidence: !exists(json, 'countryOfResidence')
      ? undefined
      : json['countryOfResidence'],
    countryOfCitizenship: !exists(json, 'countryOfCitizenship')
      ? undefined
      : json['countryOfCitizenship'],
  }
}

export function CreateUserRequestParamsToJSON(
  value?: CreateUserRequestParams | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    firstName: value.firstName,
    lastName: value.lastName,
    username: value.username,
    email: value.email,
    phone: value.phone,
    countryOfResidence: value.countryOfResidence,
    countryOfCitizenship: value.countryOfCitizenship,
  }
}
