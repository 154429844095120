/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: a26b0b3e (Wed Aug 21 18:31:49 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface DirectorDiscount
 */
export interface DirectorDiscount {
  /**
   * An explanation for why a discount was applied.
   * @type {string}
   * @memberof DirectorDiscount
   */
  desc: string
  /**
   * The amount of discount that needs to be applied against the invoice. The amount is interpreted as the currency that applies for the program's community's region.
   * @type {number}
   * @memberof DirectorDiscount
   */
  amount: number
  /**
   * The unique identifier of the student enrolled in the program.
   * @type {number}
   * @memberof DirectorDiscount
   */
  studentKey: number
  /**
   * Unique identifier for the program the student is enrolled in.
   * @type {number}
   * @memberof DirectorDiscount
   */
  programKey: number
}

export function DirectorDiscountFromJSON(json: any): DirectorDiscount {
  return DirectorDiscountFromJSONTyped(json, false)
}

export function DirectorDiscountFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): DirectorDiscount {
  if (json === undefined || json === null) {
    return json
  }
  return {
    desc: json['desc'],
    amount: json['amount'],
    studentKey: json['studentKey'],
    programKey: json['programKey'],
  }
}

export function DirectorDiscountToJSON(value?: DirectorDiscount | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    desc: value.desc,
    amount: value.amount,
    studentKey: value.studentKey,
    programKey: value.programKey,
  }
}
