/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: a26b0b3e (Wed Aug 21 18:31:49 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface RemoveLicensingEnrollmentRequestBodyLicensingEnrollments
 */
export interface RemoveLicensingEnrollmentRequestBodyLicensingEnrollments {
  /**
   * The unique identifier for the student the tuition payment is for.
   * @type {number}
   * @memberof RemoveLicensingEnrollmentRequestBodyLicensingEnrollments
   */
  studentKey: number
  /**
   * The unique identifier for the program the tuition payment is for.
   * @type {number}
   * @memberof RemoveLicensingEnrollmentRequestBodyLicensingEnrollments
   */
  programKey: number
  /**
   * Describes the semester as an identifier by which you can tell which licensing enrollment record should be removed.
   * @type {string}
   * @memberof RemoveLicensingEnrollmentRequestBodyLicensingEnrollments
   */
  feeTypeKey: RemoveLicensingEnrollmentRequestBodyLicensingEnrollmentsFeeTypeKeyEnum
}

/**
 * @export
 * @enum {string}
 */
export enum RemoveLicensingEnrollmentRequestBodyLicensingEnrollmentsFeeTypeKeyEnum {
  SemesterOneLicensingFee = 'semesterOneLicensingFee',
  SemesterTwoLicensingFee = 'semesterTwoLicensingFee',
}

export function RemoveLicensingEnrollmentRequestBodyLicensingEnrollmentsFromJSON(
  json: any
): RemoveLicensingEnrollmentRequestBodyLicensingEnrollments {
  return RemoveLicensingEnrollmentRequestBodyLicensingEnrollmentsFromJSONTyped(
    json,
    false
  )
}

export function RemoveLicensingEnrollmentRequestBodyLicensingEnrollmentsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): RemoveLicensingEnrollmentRequestBodyLicensingEnrollments {
  if (json === undefined || json === null) {
    return json
  }
  return {
    studentKey: json['studentKey'],
    programKey: json['programKey'],
    feeTypeKey: json['feeTypeKey'],
  }
}

export function RemoveLicensingEnrollmentRequestBodyLicensingEnrollmentsToJSON(
  value?: RemoveLicensingEnrollmentRequestBodyLicensingEnrollments | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    studentKey: value.studentKey,
    programKey: value.programKey,
    feeTypeKey: value.feeTypeKey,
  }
}
