/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: a26b0b3e (Wed Aug 21 18:31:49 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { ProgramType, ProgramTypeFromJSON, ProgramTypeToJSON } from './'

/**
 * Object that pairs a student name and a program type, where the student is enrolled to that program type.
 * @export
 * @interface StudentToProgramType
 */
export interface StudentToProgramType {
  /**
   * The unique identifier of the student.
   * @type {number}
   * @memberof StudentToProgramType
   */
  studentKey: number
  /**
   * The first name of the student.
   * @type {string}
   * @memberof StudentToProgramType
   */
  studentName: string
  /**
   * The unique identifier of the program.
   * @type {number}
   * @memberof StudentToProgramType
   */
  programKey: number
  /**
   *
   * @type {ProgramType}
   * @memberof StudentToProgramType
   */
  programType: ProgramType
}

export function StudentToProgramTypeFromJSON(json: any): StudentToProgramType {
  return StudentToProgramTypeFromJSONTyped(json, false)
}

export function StudentToProgramTypeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): StudentToProgramType {
  if (json === undefined || json === null) {
    return json
  }
  return {
    studentKey: json['studentKey'],
    studentName: json['studentName'],
    programKey: json['programKey'],
    programType: ProgramTypeFromJSON(json['programType']),
  }
}

export function StudentToProgramTypeToJSON(
  value?: StudentToProgramType | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    studentKey: value.studentKey,
    studentName: value.studentName,
    programKey: value.programKey,
    programType: ProgramTypeToJSON(value.programType),
  }
}
