import { reinterpretYearMonthDayAsLocalTime } from './reinterpretYearMonthDayAsLocalTime'

/** Use default locale and timeZone as UTC to account for the difference of the
 *  UTC date stored in the database and the locale timezone when formatting for
 *  program details display.
 *  isUTC param will be use to convert a date into UTC and then convert it into a string
 */
export const dateFormatter = new Intl.DateTimeFormat()
const GET_ONLY_DATE_PART_FROM_STRING = 10
const standardOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
}
export const dateTimeFormatterUS = new Intl.DateTimeFormat(
  'en-US',
  standardOptions
)

export const dateToDashString = (date: Date, isUTC?: boolean): string => {
  if (isUTC) {
    return date.toISOString().substring(0, GET_ONLY_DATE_PART_FROM_STRING)
  } else {
    const dateParts = convertDateFromLocaleToDefault(date)

    const dateArray = dateParts.split('/').map((it) => {
      if (parseInt(it) < 10) {
        it = `0${parseInt(it)}`
      }
      return it
    })

    return [dateArray[2], dateArray[0], dateArray[1]].join('-')
  }
}

/**
 *  @param date
 * Use Date.UTC to account for the difference of the
 *  date stored in the database and the locale timezone offset
 *  when formatting for program details display.
 * e.g. providing `Tue Aug 01 2023 10:00:00 GMT+1000 (Australian Eastern Standard Time)`
 * returns `Tue Aug 01 2023 00:00:00 GMT+1000 (Australian Eastern Standard Time)`
 */
export const getDateWithZeroHourOffset = (date: Date): Date => {
  return new Date(
    Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())
  )
}
/**
 * Transform a Date in an string with format `MM/DD/YYYY`
 * @param date - `Date` Date that will be transform to a string
 * @param isUTC - `boolean` if is false or omitted, transform the date in your local time zone,
 * if is true, transform the date as it come from DB
 * @returns a date converted to a string
 */

export const dateToSlashString = (date: Date, isUTC?: boolean): string => {
  if (isUTC) {
    const dateArray = date
      .toISOString()
      .substring(0, GET_ONLY_DATE_PART_FROM_STRING)
      .split('-')
    return [dateArray[1], dateArray[2], dateArray[0]].join('/')
  } else {
    const dateParts = convertDateFromLocaleToDefault(date)

    const dateArray = dateParts.split('/').map((it) => {
      if (parseInt(it) < 10) {
        it = `0${parseInt(it)}`
      }
      return it
    })
    return [dateArray[0], dateArray[1], dateArray[2]].join('/')
  }
}

const options: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  hour12: false,
}
const dateTimeFormatter = new Intl.DateTimeFormat('en-US', options)

/**
 * Utility function that takes in a UTC date and formats to a string for use in a native date-time picker (type="datetime-local").
 * @param {Date} date - A date in UTC time (or ISO format)
 * @returns a string in the following format: "yyyy-MM-ddThh:mm"
 */
export const dateWithTimeToDashString = (date: Date): string => {
  const [separatedDate, separatedTime] = dateTimeFormatter
    .format(date)
    .split(',')

  const [mm, dd, yyyy] = separatedDate.split('/')
  const time = separatedTime.trim()

  return `${yyyy}-${mm}-${dd}T${time}`
}

export const parsedDate = (dateString: string): number => {
  return Date.parse(dateString)
}

export const isValidDateString = (dateString: string): boolean => {
  return !isNaN(parsedDate(dateString))
}

export const isFirstDateStringBeforeSecond = (
  firstDate: Date,
  secondDate: Date
): boolean => {
  return (
    parsedDate(firstDate.toUTCString()) < parsedDate(secondDate.toUTCString())
  )
}

export const isFirstDateStringBeforeOrAtSecond = (
  firstDate: Date,
  secondDate: Date
): boolean => {
  return (
    parsedDate(firstDate.toUTCString()) <= parsedDate(secondDate.toUTCString())
  )
}

export const isSameDate = (firstDate: Date, secondDate: Date): boolean => {
  return (
    parsedDate(firstDate.toUTCString()) === parsedDate(secondDate.toUTCString())
  )
}

export const dayOfTheWeekAsStringLiteral = (date: Date): string => {
  return Intl.DateTimeFormat('en-US', { weekday: 'long' }).format(date)
}

export const areDatesInRange = (
  range: {
    start: Date
    end: Date
  },
  dateInQuestion: Date
): boolean => {
  return (
    dateUtilities.isFirstDateStringBeforeOrAtSecond(
      range.start,
      dateInQuestion
    ) &&
    dateUtilities.isFirstDateStringBeforeOrAtSecond(dateInQuestion, range.end)
  )
}

export const dateToSlashStringReinterpretedAsLocal = (date: Date): string => {
  return dateToSlashString(reinterpretYearMonthDayAsLocalTime(date))
}

/**
 * Takes in a date and transforms it into a string in format yyyy-mm-dd in UTC
 *
 * @param {Date} date Any date object
 * @returns {String} yyyy-mm-dd format
 */
export const utcDateString = (date: Date): string => {
  try {
    return date.toISOString().split('T')[0]
  } catch (err) {
    return new Date().toDateString().split('T')[0]
  }
}

export const convertDateFromLocaleToDefault = (
  date: Date,
  locale: string = Intl.DateTimeFormat().resolvedOptions().locale
): string => {
  /*
    if a date is not UTC but has international format, 
    let's make the format en-US not avoid failure 
    */
  return locale !== 'en-US'
    ? dateTimeFormatterUS.format(date)
    : dateFormatter.format(date)
}

const dateUtilities = {
  dateToDashString,
  isValidDateString,
  parsedDate,
  isFirstDateStringBeforeSecond,
  isFirstDateStringBeforeOrAtSecond,
  areDatesInRange,
  dateToSlashStringReinterpretedAsLocal,
  utcDateString,
  convertDateFromLocaleToDefault,
  dateTimeFormatterUS,
  dateFormatter,
}

export default dateUtilities

/**
 * Returns the year part of a date as a string in UTC.
 * 
 * @param {Date} date - The date object from which to extract the year.
 * @returns {string} The year in UTC as a string.
 */
export const UTCYearString = (date: Date): string => {
  return String(date.getUTCFullYear());
}