/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: a26b0b3e (Wed Aug 21 18:31:49 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  LicensingBillStudents,
  LicensingBillStudentsFromJSON,
  LicensingBillStudentsToJSON,
} from './'

/**
 *
 * @export
 * @interface LicensingBillFamilies
 */
export interface LicensingBillFamilies {
  /**
   *
   * @type {number}
   * @memberof LicensingBillFamilies
   */
  parentUserKey: number
  /**
   *
   * @type {string}
   * @memberof LicensingBillFamilies
   */
  parentFirstName: string
  /**
   *
   * @type {string}
   * @memberof LicensingBillFamilies
   */
  parentLastName: string
  /**
   *
   * @type {Array<LicensingBillStudents>}
   * @memberof LicensingBillFamilies
   */
  students: Array<LicensingBillStudents>
}

export function LicensingBillFamiliesFromJSON(
  json: any
): LicensingBillFamilies {
  return LicensingBillFamiliesFromJSONTyped(json, false)
}

export function LicensingBillFamiliesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): LicensingBillFamilies {
  if (json === undefined || json === null) {
    return json
  }
  return {
    parentUserKey: json['parentUserKey'],
    parentFirstName: json['parentFirstName'],
    parentLastName: json['parentLastName'],
    students: (json['students'] as Array<any>).map(
      LicensingBillStudentsFromJSON
    ),
  }
}

export function LicensingBillFamiliesToJSON(
  value?: LicensingBillFamilies | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    parentUserKey: value.parentUserKey,
    parentFirstName: value.parentFirstName,
    parentLastName: value.parentLastName,
    students: (value.students as Array<any>).map(LicensingBillStudentsToJSON),
  }
}
