/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: a26b0b3e (Wed Aug 21 18:31:49 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
import {
  AgreementRecordStatus,
  AgreementRecordStatusFromJSON,
  AgreementRecordStatusToJSON,
} from './'

/**
 *
 * @export
 * @interface AgreementRecord
 */
export interface AgreementRecord {
  /**
   * Unique identifier for this agreement.
   * @type {string}
   * @memberof AgreementRecord
   */
  agreementId: string
  /**
   * Unique identifier for the chosen agreement document, which is a WorkflowId in AdobeSign.
   * @type {string}
   * @memberof AgreementRecord
   */
  agreementTemplateId: string
  /**
   * Unique identifier for the chosen agreement document, which is a LibraryDocumentId in AdobeSign.
   * @type {string}
   * @memberof AgreementRecord
   */
  agreementDocumentId?: string
  /**
   * Unique identifier of the role that the agreement is being signed for.
   * @type {number}
   * @memberof AgreementRecord
   */
  roleId: number
  /**
   *
   * @type {AgreementRecordStatus}
   * @memberof AgreementRecord
   */
  status: AgreementRecordStatus
  /**
   * Unique identifier for the user who is sending out the agreement.
   * @type {number}
   * @memberof AgreementRecord
   */
  senderUserId: number
  /**
   * Unique identifier for the actor that will act as the supervisor to the individual being contracted or licensed.
   * @type {number}
   * @memberof AgreementRecord
   */
  supervisorActorId: number
  /**
   * The email address of the person being contracted/licensed.
   * @type {string}
   * @memberof AgreementRecord
   */
  signerEmail: string
  /**
   * Unique identifier for the person being contracted/licensed, populated when the user creates or links their account in the invite workflow.
   * @type {number}
   * @memberof AgreementRecord
   */
  signerUserId?: number
  /**
   * The date in which the actor is valid from in date format: `yyyy-MM-dd`.
   * @type {Date}
   * @memberof AgreementRecord
   */
  validFrom: Date
  /**
   * The date in which the actor becomes invalid on and after in date format: `yyyy-MM-dd`.  If absent, agreement does not expire until cancelled.
   * @type {Date}
   * @memberof AgreementRecord
   */
  validTo?: Date
  /**
   * The date in which the agreement was completed, and the user was given full access to P1 with their newly assigned role. In date-time format.
   * @type {Date}
   * @memberof AgreementRecord
   */
  signedDate?: Date
  /**
   * The date in which the agreement was manually cancelled, in date format. In date-time format.
   * @type {Date}
   * @memberof AgreementRecord
   */
  cancelledDate?: Date
  /**
   * Unique identifier for the actor that is created for the signer as a result of this signed agreement. Absent until agreement is signed and Actor for the new user and role is created.
   * @type {number}
   * @memberof AgreementRecord
   */
  actorId?: number
  /**
   * Unique identifier for the community in which the signer is being contracted/licensed for.
   * @type {number}
   * @memberof AgreementRecord
   */
  communityId?: number
  /**
   * This contains the date the agreement record was created
   * @type {Date}
   * @memberof AgreementRecord
   */
  dateSent?: Date
  /**
   * The type of agreement that was sent to the user this can be Bypass or Adobe
   * @type {string}
   * @memberof AgreementRecord
   */
  inviteType?: AgreementRecordInviteTypeEnum
  /**
   * This contains the user firstName  and lastName the agreement was sent to
   * @type {string}
   * @memberof AgreementRecord
   */
  signerName?: string
  /**
   * This contains the agreement role name.
   * @type {string}
   * @memberof AgreementRecord
   */
  roleName?: string
}

/**
 * @export
 * @enum {string}
 */
export enum AgreementRecordInviteTypeEnum {
  Bypass = 'Bypass',
  Adobe = 'Adobe',
}

export function AgreementRecordFromJSON(json: any): AgreementRecord {
  return AgreementRecordFromJSONTyped(json, false)
}

export function AgreementRecordFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AgreementRecord {
  if (json === undefined || json === null) {
    return json
  }
  return {
    agreementId: json['agreementId'],
    agreementTemplateId: json['agreementTemplateId'],
    agreementDocumentId: !exists(json, 'agreementDocumentId')
      ? undefined
      : json['agreementDocumentId'],
    roleId: json['roleId'],
    status: AgreementRecordStatusFromJSON(json['status']),
    senderUserId: json['senderUserId'],
    supervisorActorId: json['supervisorActorId'],
    signerEmail: json['signerEmail'],
    signerUserId: !exists(json, 'signerUserId')
      ? undefined
      : json['signerUserId'],
    validFrom: new Date(json['validFrom']),
    validTo: !exists(json, 'validTo') ? undefined : new Date(json['validTo']),
    signedDate: !exists(json, 'signedDate')
      ? undefined
      : new Date(json['signedDate']),
    cancelledDate: !exists(json, 'cancelledDate')
      ? undefined
      : new Date(json['cancelledDate']),
    actorId: !exists(json, 'actorId') ? undefined : json['actorId'],
    communityId: !exists(json, 'communityId') ? undefined : json['communityId'],
    dateSent: !exists(json, 'dateSent')
      ? undefined
      : new Date(json['dateSent']),
    inviteType: !exists(json, 'inviteType') ? undefined : json['inviteType'],
    signerName: !exists(json, 'signerName') ? undefined : json['signerName'],
    roleName: !exists(json, 'roleName') ? undefined : json['roleName'],
  }
}

export function AgreementRecordToJSON(value?: AgreementRecord | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    agreementId: value.agreementId,
    agreementTemplateId: value.agreementTemplateId,
    agreementDocumentId: value.agreementDocumentId,
    roleId: value.roleId,
    status: AgreementRecordStatusToJSON(value.status),
    senderUserId: value.senderUserId,
    supervisorActorId: value.supervisorActorId,
    signerEmail: value.signerEmail,
    signerUserId: value.signerUserId,
    validFrom: value.validFrom.toISOString().substr(0, 10),
    validTo:
      value.validTo === undefined
        ? undefined
        : value.validTo.toISOString().substr(0, 10),
    signedDate:
      value.signedDate === undefined
        ? undefined
        : value.signedDate.toISOString(),
    cancelledDate:
      value.cancelledDate === undefined
        ? undefined
        : value.cancelledDate.toISOString(),
    actorId: value.actorId,
    communityId: value.communityId,
    dateSent:
      value.dateSent === undefined ? undefined : value.dateSent.toISOString(),
    inviteType: value.inviteType,
    signerName: value.signerName,
    roleName: value.roleName,
  }
}
