/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: a26b0b3e (Wed Aug 21 18:31:49 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
/**
 *
 * @export
 * @interface EditRoleRequestBody
 */
export interface EditRoleRequestBody {
  /**
   * Unique identifier for the role that an actor is being updated to.
   * @type {number}
   * @memberof EditRoleRequestBody
   */
  roleKey?: number
  /**
   * Unique identifier for a region.
   * @type {number}
   * @memberof EditRoleRequestBody
   */
  regionKey?: number
  /**
   * Unique identifier for the actor that will supervise the actor being updated or added.
   * @type {number}
   * @memberof EditRoleRequestBody
   */
  supervisorActorKey: number
  /**
   * The date in which the actor is valid from in date-time format.
   * @type {Date}
   * @memberof EditRoleRequestBody
   */
  validFrom?: Date
  /**
   * The date in which the actor becomes invalid on and after in date-time format.
   * @type {Date}
   * @memberof EditRoleRequestBody
   */
  validTo?: Date
}

export function EditRoleRequestBodyFromJSON(json: any): EditRoleRequestBody {
  return EditRoleRequestBodyFromJSONTyped(json, false)
}

export function EditRoleRequestBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): EditRoleRequestBody {
  if (json === undefined || json === null) {
    return json
  }
  return {
    roleKey: !exists(json, 'roleKey') ? undefined : json['roleKey'],
    regionKey: !exists(json, 'regionKey') ? undefined : json['regionKey'],
    supervisorActorKey: json['supervisorActorKey'],
    validFrom: !exists(json, 'validFrom')
      ? undefined
      : new Date(json['validFrom']),
    validTo: !exists(json, 'validTo') ? undefined : new Date(json['validTo']),
  }
}

export function EditRoleRequestBodyToJSON(
  value?: EditRoleRequestBody | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    roleKey: value.roleKey,
    regionKey: value.regionKey,
    supervisorActorKey: value.supervisorActorKey,
    validFrom:
      value.validFrom === undefined ? undefined : value.validFrom.toISOString(),
    validTo:
      value.validTo === undefined ? undefined : value.validTo.toISOString(),
  }
}
