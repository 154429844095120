/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: a26b0b3e (Wed Aug 21 18:31:49 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { ProgramType, ProgramTypeFromJSON, ProgramTypeToJSON } from './'

/**
 *
 * @export
 * @interface ProgramDashboard
 */
export interface ProgramDashboard {
  /**
   * The unique identifier assigned by the system when a program is first created.
   * @type {number}
   * @memberof ProgramDashboard
   */
  programKey: number
  /**
   *
   * @type {ProgramType}
   * @memberof ProgramDashboard
   */
  programType: ProgramType
  /**
   * Id of the community in which this program is offered.
   * @type {number}
   * @memberof ProgramDashboard
   */
  communityKey: number
  /**
   * Name of the community in which this program is offered.
   * @type {string}
   * @memberof ProgramDashboard
   */
  communityName: string
  /**
   * Year in which the program is offered
   * @type {number}
   * @memberof ProgramDashboard
   */
  year: number
}

export function ProgramDashboardFromJSON(json: any): ProgramDashboard {
  return ProgramDashboardFromJSONTyped(json, false)
}

export function ProgramDashboardFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ProgramDashboard {
  if (json === undefined || json === null) {
    return json
  }
  return {
    programKey: json['programKey'],
    programType: ProgramTypeFromJSON(json['programType']),
    communityKey: json['communityKey'],
    communityName: json['communityName'],
    year: json['year'],
  }
}

export function ProgramDashboardToJSON(value?: ProgramDashboard | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    programKey: value.programKey,
    programType: ProgramTypeToJSON(value.programType),
    communityKey: value.communityKey,
    communityName: value.communityName,
    year: value.year,
  }
}
