/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: a26b0b3e (Wed Aug 21 18:31:49 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
import {
  ActorSelectOption,
  ActorSelectOptionFromJSON,
  ActorSelectOptionToJSON,
  CommunitySpecificProgramOptionsDefaultStartDates,
  CommunitySpecificProgramOptionsDefaultStartDatesFromJSON,
  CommunitySpecificProgramOptionsDefaultStartDatesToJSON,
  CommunitySpecificProgramOptionsFees,
  CommunitySpecificProgramOptionsFeesFromJSON,
  CommunitySpecificProgramOptionsFeesToJSON,
  ProgramType,
  ProgramTypeFromJSON,
  ProgramTypeToJSON,
} from './'

/**
 *
 * @export
 * @interface CommunitySpecificProgramOptions
 */
export interface CommunitySpecificProgramOptions {
  /**
   * The potential types that a program can have.
   * @type {Array<ProgramType>}
   * @memberof CommunitySpecificProgramOptions
   */
  programTypes: Array<ProgramType>
  /**
   *
   * @type {CommunitySpecificProgramOptionsFees}
   * @memberof CommunitySpecificProgramOptions
   */
  fees: CommunitySpecificProgramOptionsFees
  /**
   *
   * @type {CommunitySpecificProgramOptionsDefaultStartDates}
   * @memberof CommunitySpecificProgramOptions
   */
  defaultStartDates: CommunitySpecificProgramOptionsDefaultStartDates
  /**
   * A list of possible Actors that can become the program's Director. For each entry, the `applicableToResourceKeys` property pertains to a list of communityKeys in which this actor belongs to. A director can only be assigned to a program if they are within the program's specified community. The `applicableToResourceTypes` property will list the Program Types that this director can be assigned to.
   * @type {Array<ActorSelectOption>}
   * @memberof CommunitySpecificProgramOptions
   */
  directors: Array<ActorSelectOption>
  /**
   * A list of possible Actors that can become the program's assigned tutor. For each entry, the `applicableToResourceKeys` property pertains to a list of actorKeys that is in the upline for this tutor. A tutor can only be assigned to a program if they are within the downline of the assigned director. The `applicableToResourceTypes` property will list the Program Types that this tutor can be assigned to.
   * @type {Array<ActorSelectOption>}
   * @memberof CommunitySpecificProgramOptions
   */
  tutors: Array<ActorSelectOption>
  /**
   * A list of expired Tutors.
   * @type {Array<ActorSelectOption>}
   * @memberof CommunitySpecificProgramOptions
   */
  expiredTutors?: Array<ActorSelectOption>
  /**
   * A list of expired directors.
   * @type {Array<ActorSelectOption>}
   * @memberof CommunitySpecificProgramOptions
   */
  expiredDirectors?: Array<ActorSelectOption>
}

export function CommunitySpecificProgramOptionsFromJSON(
  json: any
): CommunitySpecificProgramOptions {
  return CommunitySpecificProgramOptionsFromJSONTyped(json, false)
}

export function CommunitySpecificProgramOptionsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CommunitySpecificProgramOptions {
  if (json === undefined || json === null) {
    return json
  }
  return {
    programTypes: (json['programTypes'] as Array<any>).map(ProgramTypeFromJSON),
    fees: CommunitySpecificProgramOptionsFeesFromJSON(json['fees']),
    defaultStartDates: CommunitySpecificProgramOptionsDefaultStartDatesFromJSON(
      json['defaultStartDates']
    ),
    directors: (json['directors'] as Array<any>).map(ActorSelectOptionFromJSON),
    tutors: (json['tutors'] as Array<any>).map(ActorSelectOptionFromJSON),
    expiredTutors: !exists(json, 'expiredTutors')
      ? undefined
      : (json['expiredTutors'] as Array<any>).map(ActorSelectOptionFromJSON),
    expiredDirectors: !exists(json, 'expiredDirectors')
      ? undefined
      : (json['expiredDirectors'] as Array<any>).map(ActorSelectOptionFromJSON),
  }
}

export function CommunitySpecificProgramOptionsToJSON(
  value?: CommunitySpecificProgramOptions | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    programTypes: (value.programTypes as Array<any>).map(ProgramTypeToJSON),
    fees: CommunitySpecificProgramOptionsFeesToJSON(value.fees),
    defaultStartDates: CommunitySpecificProgramOptionsDefaultStartDatesToJSON(
      value.defaultStartDates
    ),
    directors: (value.directors as Array<any>).map(ActorSelectOptionToJSON),
    tutors: (value.tutors as Array<any>).map(ActorSelectOptionToJSON),
    expiredTutors:
      value.expiredTutors === undefined
        ? undefined
        : (value.expiredTutors as Array<any>).map(ActorSelectOptionToJSON),
    expiredDirectors:
      value.expiredDirectors === undefined
        ? undefined
        : (value.expiredDirectors as Array<any>).map(ActorSelectOptionToJSON),
  }
}
