/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: a26b0b3e (Wed Aug 21 18:31:49 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
import {
  Address,
  AddressFromJSON,
  AddressToJSON,
  CashAmount,
  CashAmountFromJSON,
  CashAmountToJSON,
  Discount,
  DiscountFromJSON,
  DiscountToJSON,
  Fee,
  FeeFromJSON,
  FeeToJSON,
  ProgramType,
  ProgramTypeFromJSON,
  ProgramTypeToJSON,
  TuitionPayment,
  TuitionPaymentFromJSON,
  TuitionPaymentToJSON,
} from './'

/**
 *
 * @export
 * @interface Enrollment1
 */
export interface Enrollment1 {
  /**
   * The unique identifier of the student enrolled in the program.
   * @type {number}
   * @memberof Enrollment1
   */
  studentKey: number
  /**
   * The first name of the student.
   * @type {string}
   * @memberof Enrollment1
   */
  studentName: string
  /**
   * Unique identifier for the program the student is enrolled in.
   * @type {number}
   * @memberof Enrollment1
   */
  programKey: number
  /**
   *
   * @type {ProgramType}
   * @memberof Enrollment1
   */
  programType: ProgramType
  /**
   * Name of the program director or tutor, who sent or preview the invoice.
   * @type {string}
   * @memberof Enrollment1
   */
  requesterName: string
  /**
   * Email of the program director or tutor, who send or preview the invoice.
   * @type {string}
   * @memberof Enrollment1
   */
  requesterEmail: string
  /**
   * Phone number of the program director or tutor, who send or preview the invoice.
   * @type {string}
   * @memberof Enrollment1
   */
  requesterPhoneNumber?: string
  /**
   *
   * @type {Address}
   * @memberof Enrollment1
   */
  requesterAddress?: Address
  /**
   * The starting date for the program's first semester in date format: `yyyy-MM-dd`.
   * @type {Date}
   * @memberof Enrollment1
   */
  semesterOneStartDate: Date
  /**
   *
   * @type {Array<Fee>}
   * @memberof Enrollment1
   */
  fees: Array<Fee>
  /**
   *
   * @type {Array<Discount>}
   * @memberof Enrollment1
   */
  discounts?: Array<Discount>
  /**
   * name of the community to which it belongs
   * @type {string}
   * @memberof Enrollment1
   */
  communityName?: string
  /**
   *
   * @type {Array<TuitionPayment>}
   * @memberof Enrollment1
   */
  payments?: Array<TuitionPayment>
  /**
   *
   * @type {CashAmount}
   * @memberof Enrollment1
   */
  total: CashAmount
}

export function Enrollment1FromJSON(json: any): Enrollment1 {
  return Enrollment1FromJSONTyped(json, false)
}

export function Enrollment1FromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Enrollment1 {
  if (json === undefined || json === null) {
    return json
  }
  return {
    studentKey: json['studentKey'],
    studentName: json['studentName'],
    programKey: json['programKey'],
    programType: ProgramTypeFromJSON(json['programType']),
    requesterName: json['requesterName'],
    requesterEmail: json['requesterEmail'],
    requesterPhoneNumber: !exists(json, 'requesterPhoneNumber')
      ? undefined
      : json['requesterPhoneNumber'],
    requesterAddress: !exists(json, 'requesterAddress')
      ? undefined
      : AddressFromJSON(json['requesterAddress']),
    semesterOneStartDate: new Date(json['semesterOneStartDate']),
    fees: (json['fees'] as Array<any>).map(FeeFromJSON),
    discounts: !exists(json, 'discounts')
      ? undefined
      : (json['discounts'] as Array<any>).map(DiscountFromJSON),
    communityName: !exists(json, 'communityName')
      ? undefined
      : json['communityName'],
    payments: !exists(json, 'payments')
      ? undefined
      : (json['payments'] as Array<any>).map(TuitionPaymentFromJSON),
    total: CashAmountFromJSON(json['total']),
  }
}

export function Enrollment1ToJSON(value?: Enrollment1 | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    studentKey: value.studentKey,
    studentName: value.studentName,
    programKey: value.programKey,
    programType: ProgramTypeToJSON(value.programType),
    requesterName: value.requesterName,
    requesterEmail: value.requesterEmail,
    requesterPhoneNumber: value.requesterPhoneNumber,
    requesterAddress: AddressToJSON(value.requesterAddress),
    semesterOneStartDate: value.semesterOneStartDate
      .toISOString()
      .substr(0, 10),
    fees: (value.fees as Array<any>).map(FeeToJSON),
    discounts:
      value.discounts === undefined
        ? undefined
        : (value.discounts as Array<any>).map(DiscountToJSON),
    communityName: value.communityName,
    payments:
      value.payments === undefined
        ? undefined
        : (value.payments as Array<any>).map(TuitionPaymentToJSON),
    total: CashAmountToJSON(value.total),
  }
}
