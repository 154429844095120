/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: a26b0b3e (Wed Aug 21 18:31:49 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  AddTuitionPaymentRequestBody,
  AddTuitionPaymentRequestBodyToJSON,
  TuitionPaymentResponse,
  TuitionPaymentResponseFromJSON,
  UpdateInvoiceLineRequestBody,
  UpdateInvoiceLineRequestBodyToJSON,
  UpdateTuitionPaymentRequestBody,
  UpdateTuitionPaymentRequestBodyToJSON,
} from '../models'
import * as runtime from '../runtime'

export interface AddTuitionPaymentRequest
  extends runtime.BaseRequestParameters {
  body?: AddTuitionPaymentRequestBody
}

export interface RemoveTuitionPaymentRequest
  extends runtime.BaseRequestParameters {
  tuitionPaymentKey: number
}

export interface UpdateInvoiceLineRequest
  extends runtime.BaseRequestParameters {
  body?: UpdateInvoiceLineRequestBody
}

export interface UpdateTuitionPaymentRequest
  extends runtime.BaseRequestParameters {
  tuitionPaymentKey: number
  body?: UpdateTuitionPaymentRequestBody
}

/**
 *
 */
export class TuitionPaymentsApi extends runtime.BaseAPI {
  /**
   *  Adds a tuition payment or discount made by/for a parent. The parent could either be paying for the services of the tutor or the director. If the request body supplies a property of `isDiscount: true`, a discount will be created. If false, or not provided, a payment will be created.  ## Permissions: - The user must have an actor with an active Payment/addTuitionPayment grant to add a payment, else we will return a 403. - The user must have an actor with an active Payment/addTuitionCredit grant to add a discount, else we will return a 403. - The current user must have permission to view the user they are updating the payment for, meaning the user must be enrolled in a program they tutor or direct or the tutor or director is in the current user\'s downline. Otherwise we will return a 403.
   * Adds a new tuition payment or discount
   */
  async addTuitionPaymentRaw(
    requestParameters: AddTuitionPaymentRequest
  ): Promise<runtime.ApiResponse<TuitionPaymentResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/tuitionPayment`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: AddTuitionPaymentRequestBodyToJSON(requestParameters.body),
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      TuitionPaymentResponseFromJSON(jsonValue)
    )
  }

  /**
   *  Adds a tuition payment or discount made by/for a parent. The parent could either be paying for the services of the tutor or the director. If the request body supplies a property of `isDiscount: true`, a discount will be created. If false, or not provided, a payment will be created.  ## Permissions: - The user must have an actor with an active Payment/addTuitionPayment grant to add a payment, else we will return a 403. - The user must have an actor with an active Payment/addTuitionCredit grant to add a discount, else we will return a 403. - The current user must have permission to view the user they are updating the payment for, meaning the user must be enrolled in a program they tutor or direct or the tutor or director is in the current user\'s downline. Otherwise we will return a 403.
   * Adds a new tuition payment or discount
   */
  async addTuitionPayment(
    requestParameters: AddTuitionPaymentRequest
  ): Promise<TuitionPaymentResponse> {
    const response = await this.addTuitionPaymentRaw(requestParameters)
    return await response.value()
  }

  /**
   *        Delete a tuition payment or discount not yet a part of an invoice for a viewable family.        ## PERMISSIONS:       - To remove a payment, the requester must possess a Payment/deleteTuitionPayment grant, else a 403 will be returned.       - To remove a discount, the requester must possess a Payment/deleteTuitionCredit grant, else a 403 will be returned.       - The current user must have permission to view the user they are updating the payment for, meaning the user must be enrolled in a program they tutor or direct or the tutor or director is in the current user\'s downline. Otherwise we will return a 403.
   * Delete a tuition payment or discount not yet a part of a invoice
   */
  async removeTuitionPaymentRaw(
    requestParameters: RemoveTuitionPaymentRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.tuitionPaymentKey === null ||
      requestParameters.tuitionPaymentKey === undefined
    ) {
      throw new runtime.RequiredError(
        'tuitionPaymentKey',
        'Required parameter requestParameters.tuitionPaymentKey was null or undefined when calling removeTuitionPayment.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/tuitionPayment/{tuitionPaymentKey}`.replace(
        `{${'tuitionPaymentKey'}}`,
        encodeURIComponent(String(requestParameters.tuitionPaymentKey))
      ),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
      $signal: requestParameters.$signal,
    })

    return new runtime.VoidApiResponse(response)
  }

  /**
   *        Delete a tuition payment or discount not yet a part of an invoice for a viewable family.        ## PERMISSIONS:       - To remove a payment, the requester must possess a Payment/deleteTuitionPayment grant, else a 403 will be returned.       - To remove a discount, the requester must possess a Payment/deleteTuitionCredit grant, else a 403 will be returned.       - The current user must have permission to view the user they are updating the payment for, meaning the user must be enrolled in a program they tutor or direct or the tutor or director is in the current user\'s downline. Otherwise we will return a 403.
   * Delete a tuition payment or discount not yet a part of a invoice
   */
  async removeTuitionPayment(
    requestParameters: RemoveTuitionPaymentRequest
  ): Promise<void> {
    await this.removeTuitionPaymentRaw(requestParameters)
  }

  /**
   *      Update invoice lines. This endpoint is used to either update an existing invoice line or add a new invoice line to an invoice.     Additionally, update the payment amount to reflect the new total amount.          ### Permissions     - The user must have a Payment/updateInvoiceLine grant. Otherwise we will return a 403.
   */
  async updateInvoiceLineRaw(
    requestParameters: UpdateInvoiceLineRequest
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/payments/invoiceLines`,
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
      body: UpdateInvoiceLineRequestBodyToJSON(requestParameters.body),
      $signal: requestParameters.$signal,
    })

    return new runtime.VoidApiResponse(response)
  }

  /**
   *      Update invoice lines. This endpoint is used to either update an existing invoice line or add a new invoice line to an invoice.     Additionally, update the payment amount to reflect the new total amount.          ### Permissions     - The user must have a Payment/updateInvoiceLine grant. Otherwise we will return a 403.
   */
  async updateInvoiceLine(
    requestParameters: UpdateInvoiceLineRequest
  ): Promise<void> {
    await this.updateInvoiceLineRaw(requestParameters)
  }

  /**
   *  Edits a tuition payment. The tuition payment must not have been sent out in an invoice. If so, we will return a 404. We will update only the values that have changed from the current tuition payment.  ## Permissions: - The user must have a Payment/updateTuitionPayment grant. Otherwise we will return a 403.  - The current user must have permission to view the user they are updating the payment for, meaning the user must be enrolled in a program they tutor or direct or the tutor or director is in the current user\'s downline. Otherwise we will return a 403.
   * Updates a tuition payment.
   */
  async updateTuitionPaymentRaw(
    requestParameters: UpdateTuitionPaymentRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.tuitionPaymentKey === null ||
      requestParameters.tuitionPaymentKey === undefined
    ) {
      throw new runtime.RequiredError(
        'tuitionPaymentKey',
        'Required parameter requestParameters.tuitionPaymentKey was null or undefined when calling updateTuitionPayment.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/tuitionPayment/{tuitionPaymentKey}`.replace(
        `{${'tuitionPaymentKey'}}`,
        encodeURIComponent(String(requestParameters.tuitionPaymentKey))
      ),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
      body: UpdateTuitionPaymentRequestBodyToJSON(requestParameters.body),
      $signal: requestParameters.$signal,
    })

    return new runtime.VoidApiResponse(response)
  }

  /**
   *  Edits a tuition payment. The tuition payment must not have been sent out in an invoice. If so, we will return a 404. We will update only the values that have changed from the current tuition payment.  ## Permissions: - The user must have a Payment/updateTuitionPayment grant. Otherwise we will return a 403.  - The current user must have permission to view the user they are updating the payment for, meaning the user must be enrolled in a program they tutor or direct or the tutor or director is in the current user\'s downline. Otherwise we will return a 403.
   * Updates a tuition payment.
   */
  async updateTuitionPayment(
    requestParameters: UpdateTuitionPaymentRequest
  ): Promise<void> {
    await this.updateTuitionPaymentRaw(requestParameters)
  }
}
