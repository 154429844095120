/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: a26b0b3e (Wed Aug 21 18:31:49 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
import {
  AuthorizeDotNetEventWebhookRequestBodyPayload,
  AuthorizeDotNetEventWebhookRequestBodyPayloadFromJSON,
  AuthorizeDotNetEventWebhookRequestBodyPayloadToJSON,
} from './'

/**
 *
 * @export
 * @interface AuthorizeDotNetEventWebhookRequestBody
 */
export interface AuthorizeDotNetEventWebhookRequestBody {
  /**
   * The external unique identifier for the notification
   * @type {string}
   * @memberof AuthorizeDotNetEventWebhookRequestBody
   */
  notificationId?: string
  /**
   * The type of notification event
   * @type {string}
   * @memberof AuthorizeDotNetEventWebhookRequestBody
   */
  eventType?: string
  /**
   * Date the action took place which triggered the notification
   * @type {string}
   * @memberof AuthorizeDotNetEventWebhookRequestBody
   */
  eventDate?: string
  /**
   * Unique identifier for the webhook
   * @type {string}
   * @memberof AuthorizeDotNetEventWebhookRequestBody
   */
  webhookId?: string
  /**
   *
   * @type {AuthorizeDotNetEventWebhookRequestBodyPayload}
   * @memberof AuthorizeDotNetEventWebhookRequestBody
   */
  payload: AuthorizeDotNetEventWebhookRequestBodyPayload
}

export function AuthorizeDotNetEventWebhookRequestBodyFromJSON(
  json: any
): AuthorizeDotNetEventWebhookRequestBody {
  return AuthorizeDotNetEventWebhookRequestBodyFromJSONTyped(json, false)
}

export function AuthorizeDotNetEventWebhookRequestBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AuthorizeDotNetEventWebhookRequestBody {
  if (json === undefined || json === null) {
    return json
  }
  return {
    notificationId: !exists(json, 'notificationId')
      ? undefined
      : json['notificationId'],
    eventType: !exists(json, 'eventType') ? undefined : json['eventType'],
    eventDate: !exists(json, 'eventDate') ? undefined : json['eventDate'],
    webhookId: !exists(json, 'webhookId') ? undefined : json['webhookId'],
    payload: AuthorizeDotNetEventWebhookRequestBodyPayloadFromJSON(
      json['payload']
    ),
  }
}

export function AuthorizeDotNetEventWebhookRequestBodyToJSON(
  value?: AuthorizeDotNetEventWebhookRequestBody | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    notificationId: value.notificationId,
    eventType: value.eventType,
    eventDate: value.eventDate,
    webhookId: value.webhookId,
    payload: AuthorizeDotNetEventWebhookRequestBodyPayloadToJSON(value.payload),
  }
}
