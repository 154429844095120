/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: a26b0b3e (Wed Aug 21 18:31:49 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  FeeStructure,
  FeeStructureFromJSON,
  FeeStructureToJSON,
  ProgramType,
  ProgramTypeFromJSON,
  ProgramTypeToJSON,
} from './'

/**
 *
 * @export
 * @interface ProgramFeeStructure
 */
export interface ProgramFeeStructure {
  /**
   *
   * @type {ProgramType}
   * @memberof ProgramFeeStructure
   */
  programType: ProgramType
  /**
   *
   * @type {Array<FeeStructure>}
   * @memberof ProgramFeeStructure
   */
  feeStructures: Array<FeeStructure>
}

export function ProgramFeeStructureFromJSON(json: any): ProgramFeeStructure {
  return ProgramFeeStructureFromJSONTyped(json, false)
}

export function ProgramFeeStructureFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ProgramFeeStructure {
  if (json === undefined || json === null) {
    return json
  }
  return {
    programType: ProgramTypeFromJSON(json['programType']),
    feeStructures: (json['feeStructures'] as Array<any>).map(
      FeeStructureFromJSON
    ),
  }
}

export function ProgramFeeStructureToJSON(
  value?: ProgramFeeStructure | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    programType: ProgramTypeToJSON(value.programType),
    feeStructures: (value.feeStructures as Array<any>).map(FeeStructureToJSON),
  }
}
