/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: a26b0b3e (Wed Aug 21 18:31:49 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface RegionDiscourseUrl
 */
export interface RegionDiscourseUrl {
  /**
   *
   * @type {string}
   * @memberof RegionDiscourseUrl
   */
  regionName: string
  /**
   *
   * @type {string}
   * @memberof RegionDiscourseUrl
   */
  discourseUrl: string
}

export function RegionDiscourseUrlFromJSON(json: any): RegionDiscourseUrl {
  return RegionDiscourseUrlFromJSONTyped(json, false)
}

export function RegionDiscourseUrlFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): RegionDiscourseUrl {
  if (json === undefined || json === null) {
    return json
  }
  return {
    regionName: json['regionName'],
    discourseUrl: json['discourseUrl'],
  }
}

export function RegionDiscourseUrlToJSON(
  value?: RegionDiscourseUrl | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    regionName: value.regionName,
    discourseUrl: value.discourseUrl,
  }
}
