/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: a26b0b3e (Wed Aug 21 18:31:49 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface SignUpSendTokenRequestBody
 */
export interface SignUpSendTokenRequestBody {
  /**
   * Usernames are always an email address. Further, they should not be an `@classicalconversations.com` address.
   * @type {string}
   * @memberof SignUpSendTokenRequestBody
   */
  username: string
  /**
   * The type of registration: employment, agreement, or family. Please note that employment is deprecated.
   * @type {string}
   * @memberof SignUpSendTokenRequestBody
   */
  signUpType: SignUpSendTokenRequestBodySignUpTypeEnum
  /**
   * The unique identifier tied to the signUpType. This will be used to ensure that the requirements to bring a user into the system are met. For employment (deprecated) or agreement, this will be the agreementId and used to ensure the agreement is active.
   * @type {string}
   * @memberof SignUpSendTokenRequestBody
   */
  typeIdentifier: string
}

/**
 * @export
 * @enum {string}
 */
export enum SignUpSendTokenRequestBodySignUpTypeEnum {
  Employment = 'employment',
  Agreement = 'agreement',
  Family = 'family',
}

export function SignUpSendTokenRequestBodyFromJSON(
  json: any
): SignUpSendTokenRequestBody {
  return SignUpSendTokenRequestBodyFromJSONTyped(json, false)
}

export function SignUpSendTokenRequestBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SignUpSendTokenRequestBody {
  if (json === undefined || json === null) {
    return json
  }
  return {
    username: json['username'],
    signUpType: json['signUpType'],
    typeIdentifier: json['typeIdentifier'],
  }
}

export function SignUpSendTokenRequestBodyToJSON(
  value?: SignUpSendTokenRequestBody | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    username: value.username,
    signUpType: value.signUpType,
    typeIdentifier: value.typeIdentifier,
  }
}
