/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: a26b0b3e (Wed Aug 21 18:31:49 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
import {
  AgreementPrefilledFormField,
  AgreementPrefilledFormFieldFromJSON,
  AgreementPrefilledFormFieldToJSON,
  AgreementRecipient,
  AgreementRecipientFromJSON,
  AgreementRecipientToJSON,
} from './'

/**
 *
 * @export
 * @interface AgreementCreate
 */
export interface AgreementCreate {
  /**
   * Unique identifier of the Classical Conversations role that the agreement is being signed for.
   * @type {number}
   * @memberof AgreementCreate
   */
  roleId: number
  /**
   * Unique identifier for the chosen agreement document, which is a WorkflowId in AdobeSign.
   * @type {string}
   * @memberof AgreementCreate
   */
  agreementTemplateId?: string
  /**
   * Unique identifier for the chosen agreement document, which is a LibraryDocumentId in AdobeSign.
   * @type {string}
   * @memberof AgreementCreate
   */
  agreementDocumentId: string
  /**
   * Internal unique identifier for the agreement template, which points to a workflow in Adobe Sign and the role that can be invited.
   * @type {number}
   * @memberof AgreementCreate
   */
  agreementTemplateKey?: number
  /**
   * **DEPRECATED - Use supervisorActorId** Unique identifier for the user that will be the supervisor for the contracted/licensed individual. If absent, the current logged in user will be the supervisor.
   * @type {number}
   * @memberof AgreementCreate
   */
  supervisorUserId?: number
  /**
   * Unique identifier for the actor that will be the supervisor for the contracted/licensed individual. If absent, an actor with proper TeamInvite permissions of the current logged in user will be assigned as the supervisorActor.
   * @type {number}
   * @memberof AgreementCreate
   */
  supervisorActorId?: number
  /**
   * The email address of the person being contracted/licensed.
   * @type {string}
   * @memberof AgreementCreate
   */
  signerEmail: string
  /**
   * The date in which the agreement becomes valid from in date format: `yyyy-MM-dd`.
   * @type {Date}
   * @memberof AgreementCreate
   */
  validFrom: Date
  /**
   * The last day on which the agreement is valid in date format: `yyyy-MM-dd`.  If absent, agreement does not expire until cancelled.
   * @type {Date}
   * @memberof AgreementCreate
   */
  validTo?: Date
  /**
   * Unique identifier for the community in which the signer is being contracted/licensed for.
   * @type {number}
   * @memberof AgreementCreate
   */
  communityId?: number
  /**
   * List of recipients and their email addresses to send the agreement to for signing
   * @type {Array<AgreementRecipient>}
   * @memberof AgreementCreate
   */
  recipients: Array<AgreementRecipient>
  /**
   * List of form field data used to prefill the form field when creating a new Adobe Sign agreement.
   * @type {Array<AgreementPrefilledFormField>}
   * @memberof AgreementCreate
   */
  prefilledFormFields: Array<AgreementPrefilledFormField>
}

export function AgreementCreateFromJSON(json: any): AgreementCreate {
  return AgreementCreateFromJSONTyped(json, false)
}

export function AgreementCreateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AgreementCreate {
  if (json === undefined || json === null) {
    return json
  }
  return {
    roleId: json['roleId'],
    agreementTemplateId: !exists(json, 'agreementTemplateId')
      ? undefined
      : json['agreementTemplateId'],
    agreementDocumentId: json['agreementDocumentId'],
    agreementTemplateKey: !exists(json, 'agreementTemplateKey')
      ? undefined
      : json['agreementTemplateKey'],
    supervisorUserId: !exists(json, 'supervisorUserId')
      ? undefined
      : json['supervisorUserId'],
    supervisorActorId: !exists(json, 'supervisorActorId')
      ? undefined
      : json['supervisorActorId'],
    signerEmail: json['signerEmail'],
    validFrom: new Date(json['validFrom']),
    validTo: !exists(json, 'validTo') ? undefined : new Date(json['validTo']),
    communityId: !exists(json, 'communityId') ? undefined : json['communityId'],
    recipients: (json['recipients'] as Array<any>).map(
      AgreementRecipientFromJSON
    ),
    prefilledFormFields: (json['prefilledFormFields'] as Array<any>).map(
      AgreementPrefilledFormFieldFromJSON
    ),
  }
}

export function AgreementCreateToJSON(value?: AgreementCreate | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    roleId: value.roleId,
    agreementTemplateId: value.agreementTemplateId,
    agreementDocumentId: value.agreementDocumentId,
    agreementTemplateKey: value.agreementTemplateKey,
    supervisorUserId: value.supervisorUserId,
    supervisorActorId: value.supervisorActorId,
    signerEmail: value.signerEmail,
    validFrom: value.validFrom.toISOString().substr(0, 10),
    validTo:
      value.validTo === undefined
        ? undefined
        : value.validTo.toISOString().substr(0, 10),
    communityId: value.communityId,
    recipients: (value.recipients as Array<any>).map(AgreementRecipientToJSON),
    prefilledFormFields: (value.prefilledFormFields as Array<any>).map(
      AgreementPrefilledFormFieldToJSON
    ),
  }
}
