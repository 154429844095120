/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: a26b0b3e (Wed Aug 21 18:31:49 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface RegionManager
 */
export interface RegionManager {
  /**
   * The actorKey for the manager of the region.
   * @type {number}
   * @memberof RegionManager
   */
  actorKey: number
  /**
   * The name of regional manager.
   * @type {string}
   * @memberof RegionManager
   */
  name: string
}

export function RegionManagerFromJSON(json: any): RegionManager {
  return RegionManagerFromJSONTyped(json, false)
}

export function RegionManagerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): RegionManager {
  if (json === undefined || json === null) {
    return json
  }
  return {
    actorKey: json['actorKey'],
    name: json['name'],
  }
}

export function RegionManagerToJSON(value?: RegionManager | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    actorKey: value.actorKey,
    name: value.name,
  }
}
